/*--------------------------------------------------------------
/* Elements
--------------------------------------------- */
body {
  background: #f0f0f0;
}

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

ul,
ol {
  margin: 0 0 1.5em 3em;
}

ul {
  list-style: disc;

  li {
    font-size: 1.7rem;

  }
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;

}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 0;
  position: relative;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
}