/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
.col-form-label {
  font-size: 14px;
}

::placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {

  color: #666;
  border: 1px solid #ccc;
  border-radius: 0px;
  padding: 8px 10px;
  outline: 0;
  font-size: 14px;
  height: auto;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {

}

select {
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
  height: 200px;
}

input.wpcf7-form-control.wpcf7-text, input.wpcf7-form-control.wpcf7-number, input.wpcf7-form-control.wpcf7-date, textarea.wpcf7-form-control.wpcf7-textarea, select.wpcf7-form-control.wpcf7-select, input.wpcf7-form-control.wpcf7-quiz {
  color: #959595;
  font-size: 1.4rem;
  background-color: #f2f2f2;
}

input[type="submit"] {
  display: inline-block;
  font-weight: 400;
  color: #FFF;
  text-align: center;
  vertical-align: middle;
  padding: 12px 28px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}
