.cart-section {

  margin: 60px auto;

  h2 {
    margin-bottom: 30px;
  }
  .cart-table-row {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #919191;
    padding: 14px 0;

    &:last-child {
      border-bottom: 1px solid #919191;
    }

    .cart-table-row-left, .cart-table-row-right {
      display: flex;
      justify-content: space-between;
    }

    .cart-table-row-left {
      width: 50%;
    }
    .cart-table-row-right {
      width: 40%;
      padding-top: 10px;
    }

    .cart-table-img {
      max-height: 75px;
    }
    .cart-table-actions {
      text-align: right;
      font-size: 14px;
    }
    .cart-item-details {
      padding-top: 5px;
    }
    .cart-table-description {
      color: #919191;
    }
    .cart-options {
      color: #212121;
      background: transparent;
      font-size: 14px;
      font-weight: 300;
      padding: 0;
      border: none;
      &:hover {
        color: #6e6e6e;
      }

    }

  }

  .cart-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    a {
      border: 1px solid #212121;
      padding: 12px 40px;

      &:hover {
        background: #000;
        color: #FFF;
      }
    }

    .button-primary {
      background: #3EBFA4;
      color: white !important;
      padding: 12px 40px;
    }
  }

  .cart-section .have-code {
    display: block;
    margin: 14px 0;
    text-align: right;
  }

  .cart-totals {
    margin: 30px 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background: #F5F5F5;

    .cart-totals-left {
      width: 50%;
    }

    .cart-totals-right {
      display: flex;
      justify-content: space-between;
      width: 35%;
      text-align: right;
    }
    .cart-totals-total {
      font-weight: bold;
      font-size: 22px;
      line-height: 2;
    }
    .cart-totals-total {
      font-weight: bold;
      font-size: 22px;
      line-height: 2;
    }
  }
  .have-code-container {
    border: 1px solid #919191;
    padding: 16px;
    width: 50%;
    margin-left: auto;
    form {
      display: flex;
      justify-content: space-between;
    }
    input[type=text] {
      width: 70%;
      padding: 10px;
      font-size: 16px;
    }
    input[type=submit] {
      background: white;
      border: 1px solid #919191;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    input[type=submit]:hover {
      background: #212121;
    }
  }

}


.cart-table table tbody tr td.thumb figure {
  width: 80px;
  margin: 0;
}