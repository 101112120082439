
h1.checkout-heading {
  margin-top: 40px;
}

.checkout-section {

  margin: 40px auto 80px;
}

.checkout-section .checkout-table-container {
  margin-left: 124px;
}

.checkout-section h2 {
  margin-bottom: 28px;
}

.checkout-section button {
  font-size: 16px;
}

.checkout-section .checkout-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #919191;
  padding: 14px 0;
}

.checkout-section .checkout-table-row:last-child {
  border-bottom: 1px solid #919191;
}

.checkout-section .checkout-table-row .checkout-table-row-left,
.checkout-section .checkout-table-row .checkout-table-row-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-section .checkout-table-row .checkout-table-row-left {
  width: 75%;
}

.checkout-section .checkout-table-row .checkout-table-img {
  max-height: 60px;
}

.checkout-section .checkout-table-row .checkout-table-description {
  color: #919191;
}

.checkout-section .checkout-table-row .checkout-table-price {
  padding-top: 6px;
}

.checkout-section .checkout-table-row .checkout-table-quantity {
  border: 1px solid #919191;
  padding: 4px 12px;
  margin-right: 5px;
}

.checkout-section .checkout-totals {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #919191;
  padding: 18px 0;
  line-height: 2;
}

.checkout-section .checkout-totals .checkout-totals-right {
  text-align: right;
}

.checkout-section .checkout-totals .checkout-totals-total {
  font-weight: bold;
  font-size: 22px;
  line-height: 2.2;
}