/* Posts and pages
--------------------------------------------- */
.sticky {
  display: block;
}

.post,
.page {
  margin: 0;
}

.updated:not(.published) {
  display: none;
}

.page-content,
.entry-content,
.entry-summary {
  margin: 1.5em 0 0;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

/* Comments
--------------------------------------------- */
.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

/* Media
--------------------------------------------- */
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/* Captions
--------------------------------------------- */
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/* Galleries
--------------------------------------------- */
.gallery {
  margin-bottom: 1.5em;
  display: grid;
  grid-gap: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

.gallery-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}

.gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

.gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}

.gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}

.gallery-columns-9 {
  grid-template-columns: repeat(9, 1fr);
}

.gallery-caption {
  display: block;
}

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/

/* Jetpack infinite scroll
--------------------------------------------- */

/* Hide the Posts Navigation and the Footer when Infinite Scroll is in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  display: none;
}

/* Re-display the  Footer when Infinite Scroll has reached its end. */
.infinity-end.neverending .site-footer {
  display: block;
}

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

/* Accessibility
--------------------------------------------- */

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;

  &:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: #21759b;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}

/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus {
  outline: 0;
}

/* Alignments
--------------------------------------------- */
.alignleft {

  /*rtl:ignore*/
  float: left;

  /*rtl:ignore*/
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}

.alignright {

  /*rtl:ignore*/
  float: right;

  /*rtl:ignore*/
  margin-left: 1.5em;
  margin-bottom: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;
}

/*-----------------------------------------*/
.center {
  text-align: center
}

.left {
  text-align: left
}

.right {
  text-align: right
}

section {
  margin-bottom: 20px;
}

h2.section-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 40px;
  position: relative;
  z-index: 3;
  text-align: center;

  &:before,
 &:after {
    display: inline-block;
    content: "";
    border-top: .2rem solid black;
    width: 4rem;
    margin: 0 1rem;
    transform:translateY(-0.7rem);
  }
}

.container {
  max-width: 1500px;
  padding: 0 15px;
  margin: 0 auto;
}

.full-width {
  width: 100%;
}