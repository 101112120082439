/* Links
--------------------------------------------- */
a {
  color: #000;
  text-decoration: none;
}

a:visited {
  color: #000;
  text-decoration: none;
}

a:hover, a:focus, a:active {
  color: #000;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

a:hover, a:active {
  outline: 0;
  text-decoration: none;
}

/* Forms
--------------------------------------------- */

/* Forms
--------------------------------------------- */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
  transition: all ease 0.4s;
  padding: 13px 42px;
  color: $color_white;
  background-color: $primary_color;
  border: 1px solid $primary_color;
  cursor: pointer;
  font-weight: 500;
  border-radius: 0px;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: $secondary_color;
  background: $secondary_color;
}

button:active,
button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
  border-color: $secondary_color;
  background: $secondary_color;
}


/*--------------------------------------------------------------
## buttons
--------------------------------------------------------------*/
.btn_link {
  transition: all .3s;
  text-transform: uppercase;
  padding: 1.85rem 4.2rem;
  border-radius: 0;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  line-height: 1.429;
}

.btn_link {
  background: #04c4d9;
  color: #FFF;
  border: 1px solid $primary_color;
  transition: all 0.3s ease;

  &:hover {
    background: #e50000;
    color: #FFF;
    border: 1px solid $secondary_color;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
}

.btn_link--transparent {
  background: transparent;
  color: #FFF ;
  border: 1px solid #FFF;
  transition: all 0.3s ease;
  &:visited{
    color: #FFF ;
  }
  &:hover {
    background: #FFF;
    color: #000;
    border: 1px solid #FFF;
  }
}



.btn-feature {
  background-color: #000 !important;
  color: $color_white;
  border: 1px solid #333;
  &:hover {
    border: 1px solid #333;
    background: #FFF !important;
  }
}

.button-primary {
  background: #3EBFA4;
  color: white !important;
  padding: 12px 40px;
  transition: all ease 0.3s;
  &:hover{
    background: #35a48d
  }
}